import Layout from "./ui/Layout/Layout";
import Link from "next/link";
import { getLocalizedString } from "../utils/helpers";

export default function NotFound({ locale, translations, settings }) {
  return (
    <Layout locale={locale} settings={settings}>
      <div className="min-h-full bg-cover bg-top sm:bg-top">
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-white text-opacity-50 uppercase tracking-wide">
            {getLocalizedString(translations, "404-error")}
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            {getLocalizedString(translations, "404-title")}
          </h1>
          <p className="mt-2 text-lg font-medium text-white text-opacity-50">
            {getLocalizedString(translations, "404-description")}
          </p>
          {/* <div className="my-6 inline-flex mx-auto">
            {settings?.emailSubmit?.length && (
              <EmailSubmitField blok={settings?.emailSubmit[0]} />
            )}
          </div> */}
          <div className="">
            <Link href="/">
              <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-white sm:hover:bg-opacity-50">
                {getLocalizedString(translations, "404-button")}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
