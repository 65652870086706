import NotFound from "../components/NotFound";
import Storyblok from "../utils/storyblok";
import { isDev } from "constants/env";
import { StoryParams } from "storyblok-js-client";
import { useTranslations } from "context/TranslationContext";
import { GetStaticProps } from "next";
import { useMount } from "react-use";

export default function Page404({ locale, translations, settings }) {
  useTranslations(translations);

  return (
    <NotFound locale={locale} translations={translations} settings={settings} />
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  defaultLocale,
  preview = false,
}) => {
  const currentLocale = locale || defaultLocale;
  const sbParams: StoryParams = {
    version: "published",
    resolve_links: "url",
    language: currentLocale,
  };

  if (isDev() || preview) {
    sbParams.version = "draft";
    sbParams.cv = Date.now();
  }

  // Fetch Global settings
  const settings = await Storyblok.get(`cdn/stories/settings`, sbParams);
  // Fetch Static Language Labels
  const translationsTexts = await Storyblok.get(
    `cdn/datasource_entries?datasource=public-company-labels`,
    { dimension: currentLocale, per_page: 1000 }
  );

  return {
    props: {
      locale: currentLocale,
      translations: translationsTexts?.data?.datasource_entries || false,
      settings: settings?.data?.story.content || false,
    },
  };
};
